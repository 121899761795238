import React, {useState} from "react";
import "./FaqScreen.css";
import { Link } from "react-router-dom";
import GoToTop from "../GoToTop";
import arrowleft from "../assets/arrow-left.svg";
import arrowdown from "../assets/arrow-down.svg";
import logo from "../assets/cronis-logo-white.png";

function FaqScreen() {

    const [activeIndex, setActiveIndex] = useState(null);

    const faqData = [
        {
            "id":1,
            "question": '¿Qué es Cronis?',
            "answer": 'Cronis es una plataforma de contenido auditivo que ofrece una experiencia única para adultos que buscan explorar su creatividad.',
        },
        {
            "id":2,
            "question": '¿Quién puede usar Cronis?',
            "answer": 'Cronis está diseñada exclusivamente para adultos (mayores de 18 años).',
        },
        {
            "id":3,
            "question": '¿Qué tipo de contenido puedo encontrar en Cronis?',
            "answer": 'Cronis ofrece una amplia variedad de contenido, desde historias sensuales hasta meditaciones guiadas. Siempre habrá algo que se adapte a tus gustos y deseos.',
        },
        {
            "id":4,
            "question": '¿Cómo puedo suscribirme y acceder al contenido premium?',
            "answer": 'Puedes suscribirte a Cronis descargando la aplicación móvil para acceder a contenido premium y exclusivo. Una vez que te suscribas, podrás disfrutar de todo nuestro catálogo premium.',
        },
        {
            "id":5,
            "question": '¿Cronis garantiza la seguridad y privacidad de mis datos personales?',
            "answer": 'Sí, la seguridad y privacidad de tus datos son fundamentales para nosotros. Utilizamos medidas de seguridad avanzadas para proteger tu información y mantener tus datos personales confidenciales.',
        },
        {
            "id":6,
            "question": '¿Puedo ser un creador de contenido en Cronis?',
            "answer": ' ¡Claro! Si tienes una voz seductora y una imaginación ardiente, Cronis es el lugar perfecto para compartir tus creaciones eróticas y relajantes. Regístrate como creador y comienza a publicar.',
        },
        {
            "id":7,
            "question": '¿Cómo puedo ganar dinero en Cronis?',
            "answer": 'En Cronis, puedes ganar dinero de diversas maneras, incluyendo suscripciones de seguidores, ventas de contenido exclusivo y participación en programas de incentivos para creadores.',
        },
        {
            "id":8,
            "question": '¿Cómo protege Cronis la privacidad de los creadores de contenido?',
            "answer": 'Valoramos la privacidad de nuestros creadores de contenido. Todos los detalles personales y de pago se mantienen seguros y confidenciales, y puedes ajustar la visibilidad de tu perfil según tus preferencias.',
        },
        {
            "id":9,
            "question": '¿Puedo interactuar con otros miembros de la comunidad en Cronis?',
            "answer": '¡Claro! Cronis fomenta la interacción entre miembros de la comunidad. Puedes conectarte con otros oyentes y creadores, dejar comentarios y compartir tus experiencias.',
        },
        {
            "id":10,
            "question": '¿Cómo puedo reportar contenido inapropiado o abusivo?',
            "answer": 'Si encuentras contenido inapropiado o violaciones de nuestras políticas, puedes reportarlo de inmediato. Nuestro equipo revisará y tomará las medidas adecuadas.',
        },
        {
            "id":11,
            "question": '¿Cómo garantiza Cronis que el contenido es seguro y consensuado?',
            "answer": 'Revisamos y moderamos todo el contenido para asegurarnos de que cumple con nuestras políticas y está consensuado. La seguridad y el respeto son nuestras principales prioridades.',
        },
        {
            "id":12,
            "question": '¿Qué medidas de seguridad hay para evitar el acceso de menores de edad?',
            "answer": 'Cronis toma en serio la protección de los menores. Implementamos medidas como verificaciones de edad y pruebas lúdicas para garantizar que solo los adultos tengan acceso.',
        },
        {
            "id":13,
            "question": '¿Cómo puedo obtener ayuda si tengo problemas con la plataforma?',
            "answer": 'Puedes ponerse en contacto con nuestro equipo de soporte en cualquier momento a través de la sección de ayuda en la plataforma. Estamos aquí para ayudarte.',
        },
        {
            "id":14,
            "question": '¿Cronis ofrece asesoramiento o recursos relacionados con la salud sexual?',
            "answer": 'Sí, valoramos la salud sexual y ofrecemos recursos informativos relacionados con la sexualidad y el bienestar en nuestra sección de recursos.',
        },
        {
            "id":15,
            "question": '¿Cómo funcionan los pagos en Cronis?',
            "answer": 'Ofrecemos opciones de pago seguras y convenientes para suscripciones y compras dentro de la aplicación. Puedes encontrar detalles en la sección de pagos de la aplicación.',
        }
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };


    return (
        <div>
            <div className='faqpromise'>
            <header className="faqpromise__banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, #111 0%, transparent 90%), linear-gradient(to right, darkred, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/abracadabra-website.appspot.com/o/resources%2Fweb_ref%2Fabracadabra-maincover02.webp?alt=media&token=3760f7a5-3e9a-49d2-87e2-bfde1964e602&_gl=1*1c2i1cf*_ga*MjEzMDUzMDAxMy4xNjk2NDcyNDg2*_ga_CW55HF8NVT*MTY5NjQ3MjQ4Ni4xLjEuMTY5NjQ3Mjc1Ni4yNi4wLjA."
                )`, 
                backgroundPosition: "top center",
            }}>
            <div className='faqpromise__content'>
                <Link to="/" className="faqpromise__back" >
                    <img 
                    className='faqpromise__back__img'
                    src={arrowleft}
                    alt="to demoqrata helpdesk"
                    />
                    <p className='faqpromise__back__msg' href="/" >Regresar a la página de inicio</p>
                </Link>

                <div style={{ padding:"40px 0" }} >
                    <div>
                        <img
                        src={logo}
                        alt=''
                        style={{ maxWidth:"250px" }}
                        />
                    </div>
                    <h1>Preguntas Frecuentes</h1>
                    <img 
                        className='faqpromise__back__img'
                        src={arrowdown}
                        alt=""
                    />
                </div>
            </div>
            </header>

            <div className='faq'>
            {faqData.map((item, index) => (
                <div className='faq__container' key={index}>
                <div
                    className={`faq__item ${index === activeIndex ? 'active' : ''}`}
                    onClick={() => toggleAccordion(index)}
                >
                    <h3>{item.id}. {item.question}</h3>
                    <span>{index === activeIndex ? '-' : '+'}</span>
                </div>
                {index === activeIndex && <p className='faq__answer'>{item.answer}</p>}
                </div>
            ))}
            </div>

            <div className="faqpromise__form__copyright">
                <p>2024 Cronis Inc. :: All rights reserved.</p>
            </div>
            </div>
        <GoToTop/>
        </div>
    )
}

export default FaqScreen;